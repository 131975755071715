import * as React from "react";

import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="hero is-dark is-bold is-medium">
      <div className="hero-body">
        <div className="container is-narrow">
          <h1 className="title is-uppercase">Page not found</h1>
          <p className="subtitle">Please let me know if there should be something here!</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
